<template>

    <div v-bind="$attrs" class="">

        <div class="w-full">

            <div class=" flex  gap-8 flex-wrap">

                <Chart ref="consoChart" type="doughnut" :data="energyConsumptionData" :options="energyConsumptionOptions" class="w-80"></Chart>

                <Chart ref="peConsoChart" type="doughnut" :data="primaryEnergyConsumptionData" :options="primaryEnergyConsumptionOptions" class="w-80"></Chart>

                <Chart ref="costChart" type="doughnut" :data="costData" :options="costOptions" class="w-80"></Chart>

            </div>


        </div>

    </div>

</template>

<script>
import Chart from 'primevue/chart'
import GraphUtils from "@/mixins/graphUtils";


export default {
    name: "ProjectSummaryCharts",
    props: {
        project: Object
    },
    data() {
        return {}
    },
    mixins: [GraphUtils],
    components: {Chart},
    methods: {

        getProjectData(propertyPath){
            if (!this.ready)
                return
            const projectData = eval(propertyPath)
            if (projectData === undefined)
                return
            const labels = []
            const data = []
            for (const [key, value] of Object.entries(projectData)){
                labels.push(this.energyTypeByValue(key).label)
                data.push(value)
            }
            return {
                labels,
                datasets: [
                    {
                        data,
                        backgroundColor: this.palette
                    }
                ]
            }
        },

        getChartOptions(params){
            return {
                color: '#1E293B',
                plugins: {
                    title: {
                        ...this.makeTitle(params.title),
                        font: {size: 13}
                    },
                    tooltip: {
                        mode: 'index',
                        callbacks: {
                            label: function (ctx) {
                                let label = ctx.label
                                if (label)
                                    label += ' : '
                                if (ctx.parsed !== null){
                                    if (params.valueFormatter)
                                        label += params.valueFormatter(ctx.parsed)
                                    else
                                        label += ctx.parsed
                                }
                                return label
                            }
                        }
                    },
                }
            }

        }
    },

    computed: {
        ready() {
            return this.project || false
        },

        energyConsumptionData(){
            return this.getProjectData('this.project?.energyFlow.consoSplit')
        },

        energyConsumptionOptions(){
            return this.getChartOptions({
                title: this.$t('project.energySummary.energyChartTitle'),
                valueFormatter: (x) => this.formatPercent(x) + ' %'
            })
        },

        primaryEnergyConsumptionData(){
            return this.getProjectData('this.project?.energyFlow.consoPeSplit')
        },

        primaryEnergyConsumptionOptions(){
            return this.getChartOptions({
                title: this.$t('project.energySummary.primaryEnergyChartTitle'),
                valueFormatter: (x) => this.formatPercent(x) + ' %'
            })
        },

        costData(){
            return this.getProjectData('this.project?.moneyFlow.costSplit')
        },

        costOptions(){
            return this.getChartOptions({
                title: this.$t('project.energySummary.costChartTitle'),
                valueFormatter: (x) => this.formatPercent(x) + ' %'
            })
        },

    },
    mounted(){
        this.$refs.consoChart.reinit()
        this.$refs.peConsoChart.reinit()
        this.$refs.costChart.reinit()
    }

}
</script>

<style scoped>

</style>